import axios from '@/common/axios'
import qs from 'qs'

export function addFingerBacteriaDetection (data) {
  return axios({
    method: 'post',
    url: '/quality/fingerDetection/add',
    data: qs.stringify(data)
  })
}

export function deleteFingerBacteriaDetection (id) {
  return axios({
    method: 'delete',
    url: '/quality/fingerDetection/delete/' + id
  })
}

export function updateFingerBacteriaDetection (data) {
  return axios({
    method: 'put',
    url: '/quality/fingerDetection/update',
    data: qs.stringify(data)
  })
}

export function selectFingerBacteriaDetectionInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/fingerDetection/info/' + id
  })
}

export function selectFingerBacteriaDetectionList (query) {
  return axios({
    method: 'get',
    url: '/quality/fingerDetection/list',
    params: query
  })
}
