<template>
  <div id="fingerBacteriaDetection">
    <el-dialog
      :title="fingerBacteriaDetectionFormTitle"
      width="1200px"
      :visible.sync="fingerBacteriaDetectionDialogVisible"
      :close-on-click-modal="false"
      @close="fingerBacteriaDetectionDialogClose"
    >
      <el-form
        ref="fingerBacteriaDetectionFormRef"
        :model="fingerBacteriaDetectionForm"
        :rules="fingerBacteriaDetectionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="检测日期" prop="testDate">
              <el-date-picker v-model="fingerBacteriaDetectionForm.testDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测人数" prop="testNumber">
              <el-input v-model="fingerBacteriaDetectionForm.testNumber" placeholder="请输入检测人数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养基批号" prop="mediumBatchNo">
              <el-input v-model="fingerBacteriaDetectionForm.mediumBatchNo" placeholder="请输入培养基批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="可接受标准" prop="standard">
              <el-input v-model="fingerBacteriaDetectionForm.standard" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="fingerBacteriaDetectionFormTitle !== '生产人员手指菌检测记录详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="fingerDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="name"
              title="人员姓名"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="jobNo"
              title="工号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="testResults"
              title="测试结果"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="testConclusion"
              title="测试结论"
              :edit-render="{name: '$select', options: resultList, props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column v-if="fingerBacteriaDetectionFormTitle !== '生产人员手指菌检测记录详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="总结论" prop="generalConclusion">
              <el-input
                v-model="fingerBacteriaDetectionForm.generalConclusion"
                placeholder="请输入总结论"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="检测人" prop="inspector">
              <el-input v-model="fingerBacteriaDetectionForm.inspector" placeholder="请输入检测人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测日期" prop="checkDate">
              <el-date-picker v-model="fingerBacteriaDetectionForm.checkDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="fingerBacteriaDetectionForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="fingerBacteriaDetectionForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="fingerBacteriaDetectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="fingerBacteriaDetectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="fingerBacteriaDetectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="testDate" label="检测日期" />
      <el-table-column prop="testNumber" label="检测人数" />
      <el-table-column prop="mediumBatchNo" label="培养基批号" />
      <el-table-column prop="standard" label="可接受标准" />
      <el-table-column prop="generalConclusion" label="总结论" />
      <el-table-column prop="inspector" label="检测人" />
      <el-table-column prop="checkDate" label="检测日期" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="reviewDate" label="审核日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="fingerBacteriaDetectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addFingerBacteriaDetection, deleteFingerBacteriaDetection, updateFingerBacteriaDetection, selectFingerBacteriaDetectionInfo, selectFingerBacteriaDetectionList } from '@/api/quality/fingerBacteriaDetection'

export default {
  data () {
    return {
      fingerBacteriaDetectionDialogVisible: false,
      fingerBacteriaDetectionFormTitle: '',
      fingerBacteriaDetectionForm: {
        id: '',
        testDate: '',
        testNumber: '',
        mediumBatchNo: '',
        standard: '细菌菌落总数：≤100（CFU/每手）',
        generalConclusion: '',
        inspector: '',
        checkDate: '',
        reviewer: '',
        reviewDate: '',
        fingerDetailJson: ''
      },
      fingerBacteriaDetectionFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      fingerBacteriaDetectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      fingerDetailList: [],
      resultList: [
        { label: '符合规定', value: '1' },
        { label: '不符合规定', value: '2' }
      ]
    }
  },
  created () {
    selectFingerBacteriaDetectionList(this.searchForm).then(res => {
      this.fingerBacteriaDetectionPage = res
    })
  },
  methods: {
    fingerBacteriaDetectionDialogClose () {
      this.$refs.fingerBacteriaDetectionFormRef.resetFields()
      this.fingerDetailList = []
      this.fingerBacteriaDetectionForm.id = ''
    },
    fingerBacteriaDetectionFormSubmit () {
      if (this.fingerBacteriaDetectionFormTitle === '生产人员手指菌检测记录详情') {
        this.fingerBacteriaDetectionDialogVisible = false
        return
      }
      this.$refs.fingerBacteriaDetectionFormRef.validate(async valid => {
        if (valid) {
          this.fingerBacteriaDetectionForm.fingerDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.fingerBacteriaDetectionFormTitle === '新增生产人员手指菌检测记录') {
            await addFingerBacteriaDetection(this.fingerBacteriaDetectionForm)
          } else if (this.fingerBacteriaDetectionFormTitle === '修改生产人员手指菌检测记录') {
            await updateFingerBacteriaDetection(this.fingerBacteriaDetectionForm)
          }
          this.fingerBacteriaDetectionPage = await selectFingerBacteriaDetectionList(this.searchForm)
          this.fingerBacteriaDetectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.fingerBacteriaDetectionFormTitle = '新增生产人员手指菌检测记录'
      this.fingerBacteriaDetectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFingerBacteriaDetection(row.id)
        if (this.fingerBacteriaDetectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.fingerBacteriaDetectionPage = await selectFingerBacteriaDetectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.fingerBacteriaDetectionFormTitle = '修改生产人员手指菌检测记录'
      this.fingerBacteriaDetectionDialogVisible = true
      this.selectFingerBacteriaDetectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.fingerBacteriaDetectionFormTitle = '生产人员手指菌检测记录详情'
      this.fingerBacteriaDetectionDialogVisible = true
      this.selectFingerBacteriaDetectionInfoById(row.id)
    },
    selectFingerBacteriaDetectionInfoById (id) {
      selectFingerBacteriaDetectionInfo(id).then(res => {
        this.fingerBacteriaDetectionForm.id = res.id
        this.fingerBacteriaDetectionForm.testDate = res.testDate
        this.fingerBacteriaDetectionForm.testNumber = res.testNumber
        this.fingerBacteriaDetectionForm.mediumBatchNo = res.mediumBatchNo
        this.fingerBacteriaDetectionForm.standard = res.standard
        this.fingerBacteriaDetectionForm.generalConclusion = res.generalConclusion
        this.fingerBacteriaDetectionForm.inspector = res.inspector
        this.fingerBacteriaDetectionForm.checkDate = res.checkDate
        this.fingerBacteriaDetectionForm.reviewer = res.reviewer
        this.fingerBacteriaDetectionForm.reviewDate = res.reviewDate
        this.fingerDetailList = res.fingerDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFingerBacteriaDetectionList(this.searchForm).then(res => {
        this.fingerBacteriaDetectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFingerBacteriaDetectionList(this.searchForm).then(res => {
        this.fingerBacteriaDetectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFingerBacteriaDetectionList(this.searchForm).then(res => {
        this.fingerBacteriaDetectionPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
